// store.ts
import type { MapState } from '$lib/utils/map'
import { writable, type Readable } from 'svelte/store'

export const stickySection = writable<string | null>(null)

export type MapBoundsType = { south: number; west: number; north: number; east: number }
export type MapMarkerType = { lat: number; lng: number; placeId: string }

export type BlockState = {
	currentDestination: MapMarkerType | null
	searchResults: MapMarkerType[]
	searchSelected: string | null
	searchArea: Boolean
	hoveringPlaceId: string | null
	googleSdk: any | null
	googleMap: google.maps.Map | null
	mapBounds: MapBoundsType | null
	mapCenter: { lat: number; lng: number } | null
	mapMarkers: { lat: number; lng: number }[]
	recenterMap: boolean
	refetchMapBlocks: boolean
	dragStart: boolean
	mapState: MapState
}

function createInitialState() {
	return {
		currentDestination: null,
		searchResults: [],
		searchSelected: null,
		searchArea: false,
		hoveringPlaceId: null,
		googleSdk: null,
		googleMap: null,
		mapBounds: null,
		mapCenter: null,
		mapMarkers: [],
		recenterMap: false,
		refetchMapBlocks: false,
		dragStart: false,
		mapState: null,
	}
}

const store = writable<BlockState>(createInitialState())
const set = (newState: Partial<BlockState>) => store.update((state) => ({ ...state, ...newState }))

const setCurrentDestination = (dest: MapMarkerType): void => {
	set({ currentDestination: dest })
}

const setSearchResults = (places: MapMarkerType[]): void => {
	// console.log('set search results', places)
	set({ searchResults: places, mapState: 'search' })
}

const setSearchSelected = (selected: string | null): void => {
	set({ searchSelected: selected })
}

const setSearchArea = (search: Boolean): void => {
	set({ searchArea: search })
}

const setHoveringPlaceId = (locationId: string): void => {
	set({ hoveringPlaceId: locationId })
}

const cleartHoveringPlaceId = (): void => {
	set({ hoveringPlaceId: null })
}

const setGoogleSdk = (googleSdk: any): void => {
	set({ googleSdk: googleSdk })
}

const setGoogleMap = (googleMap: google.maps.Map): void => {
	set({ googleMap: googleMap })
}

const setMapBounds = (mapBounds: MapBoundsType): void => {
	set({ mapBounds: mapBounds })
}

const setMapCenter = (mapCenter: { lat: number; lng: number }): void => {
	set({ mapCenter: mapCenter })
}

const setMapMarkers = (mapMarkers: { lat: number; lng: number }[]): void => {
	set({ mapMarkers: mapMarkers })
}

const setRecenterMap = (recenterMap: boolean): void => {
	set({ recenterMap: recenterMap })
}

const setRefetchMapBlocks = (refetchMapBlocks: boolean): void => {
	set({ refetchMapBlocks: refetchMapBlocks })
}

const setDragStart = (dragStart: boolean): void => {
	set({ dragStart: dragStart })
}

const setMapState = (mapState: MapState): void => {
	set({ mapState: mapState, recenterMap: true })
}

const clearAllCustom = (): void => {
	// console.log('clear custom')
	set({
		currentDestination: null,
		searchResults: [],
		hoveringPlaceId: null,
		mapMarkers: [],
		searchArea: false,
		searchSelected: null,
	})
}

const clearCurrentDestination = (): void => {
	set({ currentDestination: null })
}

const blockState: Readable<BlockState> & {
	setCurrentDestination: typeof setCurrentDestination
	setSearchResults: typeof setSearchResults
	setSearchSelected: typeof setSearchSelected
	setSearchArea: typeof setSearchArea
	setHoveringPlaceId: typeof setHoveringPlaceId
	cleartHoveringPlaceId: typeof cleartHoveringPlaceId
	clearAllCustom: typeof clearAllCustom
	setGoogleSdk: typeof setGoogleSdk
	setGoogleMap: typeof setGoogleMap
	clearCurrentDestination: typeof clearCurrentDestination
	setMapBounds: typeof setMapBounds
	setMapCenter: typeof setMapCenter
	setMapMarkers: typeof setMapMarkers
	setRecenterMap: typeof setRecenterMap
	setRefetchMapBlocks: typeof setRefetchMapBlocks
	setDragStart: typeof setDragStart
	setMapState: typeof setMapState
} = {
	subscribe: store.subscribe,
	setCurrentDestination,
	setSearchResults,
	setSearchSelected,
	setSearchArea,
	setHoveringPlaceId,
	cleartHoveringPlaceId,
	clearAllCustom,
	setGoogleSdk,
	setGoogleMap,
	clearCurrentDestination,
	setMapBounds,
	setMapCenter,
	setMapMarkers,
	setRecenterMap,
	setRefetchMapBlocks,
	setDragStart,
	setMapState,
}

export default blockState
